<template>
  <div id="app" v-on:click.right="onRightButton" v-if="showApp">
    <notifications group="foo" position="top center" width="600" />
    <smooth-scrollbar>
      <router-view />
    </smooth-scrollbar>
  </div>
</template>

<style>
@import "./assets/css/custom.css";
</style>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },

  data() {
    return {
      changes: [],
      output: "",
      showApp: true,
    };
  },

  mounted() {
    document.addEventListener("keydown", this.keyupHandler);
  },
  destroyed() {
    document.removeEventListener("keydown", this.keyupHandler);
  },

  methods: {
    keyupHandler(event) {
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() == "i") {
        alert("You Dont Allow, Open Inspect Element");
        this.showApp = false;
      }

      if (event.ctrlKey && event.key === "c") {
        document.addEventListener("copy", function (e) {
          e.clipboardData.setData("text/plain", "Not paste");
          e.preventDefault();
        });
      }

      if (event.ctrlKey && event.key === "v") {
        document.addEventListener("paste", function (e) {
          e.clipboardData.setData("text/plain", "Not paste");
          e.preventDefault();
        });
      }
    },

    onRightButton: function (e) {
      e.preventDefault();
    },
  },
};
</script>
