// array in local storage for registered users
let users = JSON.parse(localStorage.getItem('users')) ||
    [{
        username: "admin",
        email: "admin@themesdesign.in",
        password: "123456"
    }];


import axios from "axios";
import md5  from "md5";

export function configureFakeBackend() {

    let realFetch = window.fetch;
    window.fetch = function (url, opts) {

        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(() => {

                // authenticate
                if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
                    // get parameters from post request
                    let params = JSON.parse(opts.body);

                    let formData = new URLSearchParams();
                    // formData.append('username', params.email );
                    formData.append('JMQQ7YM2KD', md5(params.email) + "|" + md5(params.password));


                    axios.post(process.env.VUE_APP_AUTHDOMAIN + "/api/ws/authentication/v1/VVY7QDM7D8", formData, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }).then(res => {
                        if (res.data.codestatus == "S") {

                            resolve({
                                ok: true,
                                text: () => Promise.resolve(JSON.stringify(res.data.resultdata))
                            });

                        } else {

                            reject('These credentials do not match our records.');
                        }
                    }).catch((error) => {
                        console.log(error)
                    });

                    return;
                }

                // get users
                if (url.endsWith('/users') && opts.method === 'GET') {
                    // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
                        resolve({
                            ok: true,
                            text: () => Promise.resolve(JSON.stringify(users))
                        });
                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // get user by id
                if (url.match(/\/users\/\d+$/) && opts.method === 'GET') {
                    // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
                        // find user by id in users array
                        let urlParts = url.split('/');
                        let id = parseInt(urlParts[urlParts.length - 1]);
                        let matchedUsers = users.filter(user => {
                            return user.id === id;
                        });
                        let user = matchedUsers.length ? matchedUsers[0] : null;

                        // respond 200 OK with user
                        resolve({
                            ok: true,
                            text: () => JSON.stringify(user)
                        });
                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // register user
                if (url.endsWith('/users/register') && opts.method === 'POST') {
                    // get new user object from post body
                    let newUser = JSON.parse(opts.body);
                    // validation
                    let duplicateUser = users.filter(user => {
                        return user.username === newUser.username;
                    }).length;
                    if (duplicateUser) {
                        reject("Username '" + newUser.username + "' is already taken");
                        return;
                    }

                    // save new user
                    newUser.id = users.length ? Math.max(...users.map(user => user.id)) + 1 : 1;
                    users.push(newUser);
                    localStorage.setItem('users', JSON.stringify(users));

                    // respond 200 OK
                    resolve({
                        ok: true,
                        text: () => Promise.resolve()
                    });

                    return;
                }

                // pass through any requests not handled above
                realFetch(url, opts).then(response => resolve(response));

            }, 500);
        });
    }
}