import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout2',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve() {
                location.href = "/logout";
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }

                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },

















    {
        path: '/view-client-assesment',
        name: 'viewClientAssesment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/assesment/ClientAssesment')
    },
    {
        path: '/view-public-assesment',
        name: 'ViewPublicAssesment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/AssesmentPublic/ViewPublicAssesment')
    },

    {
        path: '/navigator-same',
        name: 'NavigatorSame',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/NavigatorSame')
    },

    {
        path: '/help-desk',
        name: 'HelpDesk',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/helpdesk/helpdesk')
    },












    {
        path: '/view-client',
        name: 'viewClient',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/master/customer')
    },
    {
        path: '/view-sector',
        name: 'viewSector',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/master/sector')
    },
    {
        path: '/view-contract',
        name: 'viewContract',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/master/contract')
    },
    {
        path: '/view-holding',
        name: 'viewHolding',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/master/holding')
    },
    {
        path: '/view-master-assesment',
        name: 'viewMasterAssesment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/config_assesment/assesment')
    },
    {
        path: '/view-howpay',
        name: 'viewHowpay',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/master/howpay')
    },
    {
        path: '/view-work-package',
        name: 'viewWorkpackage',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/master/workpackage')
    },
    {
        path: '/view-segmentation-owner',
        name: 'viewWorkpackage',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/master/segmentasiOwner')
    },
    {
        path: '/view-user',
        name: 'viewUser',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/karya/user/user')
    },








]
