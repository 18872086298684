import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  state:{
    dataLogin:null
  },
  mutations:{
    setDataLogin: function(state, payload){
      state.dataLogin = payload
    },
  }
})

export default store

