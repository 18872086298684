export default class Config {

    static modeDev = false;

    // ######## API GET MASTER
    // URL Master Customer
    static getContact = '/api/ws/transaction/v1/getContact';
    static getPublicAssessment = '/api/ws/transaction/v1/getPublicAssessment';
    static getPublicAssessmentFullJSONType = '/api/ws/transaction/v1/getPublicAssessmentFullJSONType';
    static getPublicAssessmentDetail = '/api/ws/transaction/v1/getPublicAssessmentDetail';
    static getContactNew = '/api/ws/transaction/v1/getContactNew';
    static formResetPassword = '/api/ws/authentication/v1/formResetPassword';
    static getSurveySubmition = '/api/ws/transaction/v1/getSurveySubmition';
    static insertContactMultipartFormType = '/api/ws/transaction/v1/insertContactMultipartFormType';
    static deleteContact = '/api/ws/transaction/v1/deleteContact';

    static creteHelpdeskTicketMultipartFormType = '/api/ws/transaction/v1/creteHelpdeskTicketMultipartFormType';
    static getHelpdeskTicket = '/api/ws/transaction/v1/getHelpdeskTicket';
    

    static getCountryState = '/api/ws/master/v1/getCountryState';
    // URL Master Sector
    static getLokasi = '/api/ws/master/v1/getLokasi';
    static getStatusContract = '/api/ws/master/v1/getStatusContract';
    static getSektor = '/api/ws/master/v1/getSektor';
    static insertSektor = '/api/ws/master/v1/insertSektor';
    static logout = '/api/ws/authentication/v1/logout';
    static changePassword = '/api/ws/authentication/v1/changePassword';
    static deleteSektor = '/api/ws/master/v1/deleteSektor';
    static getNotification = '/api/ws/transaction/v1/getNotification';
    static readNotification = '/api/ws/transaction/v1/readNotification';

    static getJenisKontrak = '/api/ws/master/v1/getJenisKontrak';
    static insertJenisKontrak = '/api/ws/master/v1/insertJenisKontrak';
    static deleteJenisKontrak = '/api/ws/master/v1/deleteJenisKontrak';

    static getListActivity = '/api/ws/workflow/v1/getListActivity';
    static actionApproveWorkflow = '/api/ws/workflow/v1/actionApproveWorkflow';


    static dashboardGetLokasi = '/api/ws/dashboard/v1/getLokasi';
    static getTotalNilaiKontrak = '/api/ws/dashboard/v1/getTotalNilaiKontrak';
    static getJumlahKontrak = '/api/ws/dashboard/v1/getJumlahKontrak';
    static dashbarodgetSektor = '/api/ws/dashboard/v1/getSektor';
    static dashgetSectionPerYear= '/api/ws/dashboard/v1/getSectionPerYear';
    static dashgetTotalCompany= '/api/ws/dashboard/v1/getTotalCompany';
    static dashgetTotalProject= '/api/ws/dashboard/v1/getTotalProject';
    static dashgetSegment = '/api/ws/dashboard/v1/getSegment';
    static dashgetProjectPerYear = '/api/ws/dashboard/v1/getProjectPerYear';
    static dashgetTop5 = '/api/ws/dashboard/v1/getTop5';
    static dashgetHighlightIssue = '/api/ws/dashboard/v1/getHighlightIssue';

    static getPaketPekerjaan = '/api/ws/master/v1/getPaketPekerjaan';
    static insertPaketPekerjaan = '/api/ws/master/v1/insertPaketPekerjaan';
    static deletePaketPekerjaan = '/api/ws/master/v1/deletePaketPekerjaan';

    static getSegmentasiOwner = '/api/ws/master/v1/getSegmentasiOwner';
    static insertSegmentasiOwner = '/api/ws/master/v1/insertSegmentasiOwner';
    static deleteSegmentasiOwner = '/api/ws/master/v1/deleteSegmentasiOwner';

    static getHolding = '/api/ws/master/v1/getHolding';
    static insertHolding = '/api/ws/master/v1/insertHolding';
    static deleteHolding = '/api/ws/master/v1/deleteHolding';

    static getCaraPembayaran = '/api/ws/master/v1/getCaraPembayaran';
    static insertCaraPembayaran = '/api/ws/master/v1/insertCaraPembayaran';
    static deleteCaraPembayaran = '/api/ws/master/v1/deleteCaraPembayaran';

    static getUser = '/api/ws/transaction/v1/getUser';
    static insertContactLine = '/api/ws/transaction/v1/insertContactLine';
    static submitSurveyPageJSONType = '/api/ws/transaction/v1/submitSurveyPageJSONType';

    static getSurvey = '/api/ws/transaction/v1/getSurvey';

    
    
}   

